import * as customizeTypes from "./customize-type";

const INITIAL_STATE = {
    themeColor: '',
    textColor: '',
    linkColor: '',
};

const customizeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case customizeTypes.THEME_COLOR:
            return { ...state, themeColor: action.payload };

        case customizeTypes.TEXT_COLOR:
            return { ...state, textColor: action.payload };

        case customizeTypes.LINK_COLOR:
            return { ...state, linkColor: action.payload };

        default:
            return state;
    }
};

export default customizeReducer;