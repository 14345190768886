import React, { Suspense } from "react";
import ReactDOM from 'react-dom';
import App from './App';
import Loader from "./components/loader";
import { Provider } from "react-redux";
import store from "./redux/store";
import './index.css'


window.store = store;
console.log("Store updated", store.getState());

ReactDOM.render(
	<Suspense fallback={<Loader />}>
		<Provider store={store}>
			<App />
		</Provider>
	</Suspense>,
	document.getElementById('root')
);
