import { lazy } from "react";
import { Route } from "react-router-dom";

export const routes = {
	landingPage: {
		name: "Landing Page",
		path: "/",
		component: lazy(() => import("../modules/LandingPage/LandingPage")),
		type: Route
	},
	dashboard: {
		name: "Dashboard",
		path: "/dashboard",
		component: lazy(() => import("../modules/homepage/HomePage")),
		type: Route,
	},
};

export const renderRoutes = Object.entries(routes);