import { combineReducers } from "redux";
import configReducer from "./configration/config-reducer";
import customizeReducer from "./customize/customize-reducer";

const rootReducer = combineReducers({
    config: configReducer,
    customize: customizeReducer,
});

export default rootReducer;
