import * as configTypes from "./config-type";

const INITIAL_STATE = {
    templateId: 1,
    user: {},
    isInput: false,
    profileUrl: '',
    logoUrl: '',
};

const configReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case configTypes.SELECT_TEMPLATE:
            return { ...state, templateId: action.payload };

        case configTypes.USER_DETAIL:
            return {
                ...state,
                user: action.payload.user,
                isInput: action.payload.isInput,
            };

        case configTypes.PROFILE_IMAGE:
            return { ...state, profileUrl: action.payload };

        case configTypes.LOGO_IMAGE:
            return { ...state, logoUrl: action.payload };

        default:
            return state;
    }
};

export default configReducer;