export const SELECT_TEMPLATE = 'SELECT_TEMPLATE';
export const USER_DETAIL = 'USER_DETAIL';
export const PROFILE_IMAGE = 'PROFILE_IMAGE';
export const LOGO_IMAGE = 'LOGO_IMAGE';








